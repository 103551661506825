.home-html {
    background-color: #E5E5E5;
}

.home {
    button {
        border-radius: 100px;
        color: white;
        padding: 7px 20px;
        margin: 10px 0px;
        border: none;
        font-size: 30px;
        font-weight: bold; 
        cursor: pointer;
        width: 320px;
        height: 80px;
    } 
    
    #hinata {
        background-color: #00B8FF;
    }
    #nogi {
        background-color: #7E1083;
    }
    #sakura {
        background-color: #ffa1bf;
    }
    
    .title {
        width: 300px;
        height: 300px;
        margin: 0px auto 50px auto;
        border-radius: 50%;
        background-color: #ff7d7d;
        color: white;
        text-align: center;
        line-height: 80px;
        font-size: 40px;
        font-weight: bold;
    }
    .space{
        height: 90px;
    }
    
    #fibon {
        margin-top: 35px;
        padding-bottom: 10px;
    }
}